<template>
    <Section class="bg-white">
        <Container class="relative bg-slate-50">
            <div class="mx-auto max-w-7xl px-6 lg:flex lg:flex-col lg:items-center lg:px-8 lg:text-center">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Alles in nur drei Schritten
                    </h2>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Vom Anfang bis zum Ende - sind wir für Sie da!</p>
                </div>
                <div class="mx-auto mt-12 max-w-2xl sm:mt-20 lg:mt-12 lg:max-w-none">
                    <div class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        <div v-for="feature in features" :key="feature.name" class="">
                            <div
                                class="relative mr-6 mt-1 flex flex-col items-center lg:mb-6 lg:mr-0 lg:flex-row lg:justify-center"
                            >
                                <img :src="feature.img" alt="" class="h-44" />
                            </div>
                            <div class="flex flex-auto flex-col text-base leading-7 text-gray-600 lg:px-3">
                                <p class="text-base font-semibold leading-7 text-gray-900">
                                    {{ feature.name }}
                                </p>
                                <p class="flex-auto">
                                    {{ feature.description }}
                                </p>
                                <!-- <p class="mt-6">
                                    <a
                                        :href="feature.href"
                                        class="text-sm font-semibold leading-6 text-sky-600"
                                        >Learn more
                                        <span aria-hidden="true">→</span></a
                                    >
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </Section>
</template>

<script setup>
import Section from '@/Components/Fragments/BaseSection.vue'
import Container from '@/Components/Fragments/BaseContainer.vue'
import { ChatBubbleLeftIcon } from '@heroicons/vue/24/outline'
import { BanknotesIcon } from '@heroicons/vue/24/outline'
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline'

const features = [
    {
        name: 'Beratung.',
        description: 'Welcher Lift ist der richtige für Sie? Wir beraten Sie gerne.',
        icon: ChatBubbleLeftIcon,
        img: '/img/home/support.png',
    },
    {
        name: 'Förderung.',
        description: 'Wir helfen Ihnen bei der Beantragung von Fördermitteln.',
        icon: BanknotesIcon,
        img: '/img/home/funding.png',
    },
    {
        name: 'Beauftragung.',
        description: 'Unser Netzwerk an Experten steht Ihnen zur Verfügung.',
        icon: ClipboardDocumentCheckIcon,
        img: '/img/home/order.png',
    },
]
</script>
