<template>
    <Section class="bg-white" id="foerderung">
        <Container class="relative bg-slate-50">
            <div
                class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0"
            >
                <div class="lg:row-start-2 lg:max-w-md">
                    <h2 class="text-3xl font-bold tracking-tight text-slate-950 sm:text-4xl">
                        Bis zu <span class="px-1 text-sky-600">100%</span>
                        <br class="sm:hidden md:block" />
                        Förderung möglich
                    </h2>
                    <p class="mt-6 text-lg leading-8 text-slate-600">
                        Wir helfen Ihnen bei der Beantragung von Fördermitteln für Ihren Treppenlift oder
                        Elektrorollstuhl.
                    </p>
                </div>
                <img
                    src="https://images.pexels.com/photos/6787781/pexels-photo-6787781.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Product screenshot"
                    class="relative min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
                    width="2432"
                    height="1442"
                />
                <div class="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-2">
                    <dl class="max-w-xl space-y-8 text-base leading-7 text-slate-500 lg:max-w-none">
                        <div v-for="feature in features" :key="feature.name" class="relative">
                            <dt class="inline-block font-semibold text-slate-600">
                                <!-- <component
                                        :is="feature.icon"
                                        class="absolute left-1 top-1 h-5 w-5 text-sky-600"
                                        aria-hidden="true"
                                    /> -->
                                {{ feature.name }}
                            </dt>
                            {{ ' ' }}
                            <dd class="inline">
                                {{ feature.description }}
                            </dd>
                        </div>
                    </dl>
                    <div class="mt-12 flex items-center gap-x-1">
                        <Link
                            :href="route('stairlift.index')"
                            class="rounded-l-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                        >
                            Lift-Check <span aria-hidden="true">→</span></Link
                        >
                        <Link
                            :href="route('wheelchair.index')"
                            class="rounded-r-md bg-lime-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                        >
                            E-Rolli-Check
                            <span aria-hidden="true">→</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div
                class="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
                aria-hidden="true"
            >
                <div
                    class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#e0f2fe] to-[#0284C7] opacity-25"
                    style="
                        clip-path: polygon(
                            74.1% 44.1%,
                            100% 61.6%,
                            97.5% 26.9%,
                            85.5% 0.1%,
                            80.7% 2%,
                            72.5% 32.5%,
                            60.2% 62.4%,
                            52.4% 68.1%,
                            47.5% 58.3%,
                            45.2% 34.5%,
                            27.5% 76.7%,
                            0.1% 64.9%,
                            17.9% 100%,
                            27.6% 76.8%,
                            76.1% 97.7%,
                            74.1% 44.1%
                        );
                    "
                />
            </div>
        </Container>
    </Section>
</template>

<script setup>
import Section from '@/Components/Fragments/BaseSection.vue'
import Container from '@/Components/Fragments/BaseContainer.vue'
import { Link } from '@inertiajs/vue3'

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/vue/20/solid'

const features = [
    {
        name: 'Pflegegrad.',
        description: 'Bereits mit einem Pflegegrad der Pflegestufe 1, können 4000€ pro Person beantragt werden.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Regionale Fördermittel.',
        description:
            'Viele Bundesländer, Städte und Kommunen bieten ihre eigenen Fördermöglichkeiten an. Wir helfen Ihnen, sich zurechtzufinden.',
        icon: LockClosedIcon,
    },
    {
        name: 'KFW Kredite.',
        description:
            'Auch die KFW Bank unterstützt oftmals ihr Umbauvorhaben. Bei uns erfahren sie alles zur Kombinierbarkeit der Zuschussanträge.',
        icon: ServerIcon,
    },
]
</script>
